import { GInput } from '@/design-system/v3/g-input';
import { BusinessUser, Course, CourseTeacher } from '@/web/types';
import SearchIcon from '@/icons/search.svg?react';
import { useDebounce } from '@/common/hooks/use-debounce';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  FETCH_COURSE_DETAIL_QUERY,
  FETCH_COURSE_MEMBERS_QUERY,
  FETCH_TEACHER_CLASSES,
  getBusinessUsers,
} from '@/web/endpoints';
import { GLoader } from '@/design-system/g-loader';
import { GAvatar } from '@/design-system/g-avatar';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';
import { courseToForm, dateWithTime, handleValidationError, highlightQuery } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import PeopleAddIcon from '@/icons/people-add.svg?react';
import clsx from 'clsx';
import { GCheckbox } from '@/design-system/v3/g-checkbox';
import { CourseOptionsMenuContext } from '@/web/views/course-detail/course-options-menu/context';
import { add, addYears, parseISO } from 'date-fns';
import { updateBusinessCourse } from '@/business/endpoints';
import { COURSES_PATH } from '@/web/routes';
import { CourseForm } from '@/web/views/course-detail/course-options-menu/index';
import { useRecoilValue } from 'recoil';
import { businessCourseTypesState } from '@/business/atoms';
import { useNavigate } from 'react-router-dom';

const FETCH_BUSINESS_TEACHERS = 'FETCH_BUSINESS_TEACHERS';

export const ChangeTeacherMode = () => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { course, setActions, onClose } = useContext(CourseOptionsMenuContext);
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 500) as string;
  const [teachers, setTeachers] = useState<CourseTeacher[]>([]);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const courseTypes = useRecoilValue(businessCourseTypesState);

  const { data: users, isLoading } = useQuery([FETCH_BUSINESS_TEACHERS, businessUuid, debouncedQuery], async () => {
    if (businessUuid && debouncedQuery.length >= 3) {
      return getBusinessUsers(businessUuid, {
        page: 1,
        size: 10,
        type: 'TEACHER,STAFF,ADMIN',
        query: debouncedQuery,
      });
    }
  });

  const filteredTeachers = useMemo<BusinessUser[]>(() => {
    const resultTeachers: CourseTeacher[] = users?.content ?? [];

    const allTeachers = [
      ...teachers,
      ...resultTeachers.filter((teacher) => !teachers.find((t) => t.id === teacher.id)),
    ];

    return allTeachers.sort((a, b) => {
      if (selected[a.id] && !selected[b.id]) {
        return -1;
      }

      if (!selected[a.id] && selected[b.id]) {
        return 1;
      }

      return a.fullName.localeCompare(b.fullName);
    });
  }, [users, teachers, selected]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleSelect = useCallback(
    (user: BusinessUser) => () => {
      if (!selected[user.id]) {
        setTeachers((prev) => [...prev, user]);
      }

      setSelected((prev) => ({ ...prev, [user.id]: !prev[user.id] }));
    },
    [selected],
  );

  useEffect(() => {
    Object.entries(selected).forEach(([id, isSelected]) => {
      // Remove teacher from list if it's not selected
      if (!isSelected) {
        setTeachers((prev) => prev.filter((teacher) => teacher.id !== id));
      }
    });
  }, [selected]);

  const { mutate, isLoading: isSubmitting } = useMutation(async () => {
    if (!businessUuid) {
      return;
    }

    const data: any = courseToForm(course, courseTypes);

    const startAt = dateWithTime(data.date, data.from);
    data.startAt = startAt.toISOString();
    data.originalStartAt = course.originalStartAt;

    const endAt = add(startAt, { minutes: data.duration });
    data.endAt = endAt.toISOString();

    data.teacherIds = Object.entries(selected)
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => id);

    data.teachers = Object.entries(selected)
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => ({ id }));

    data.description = course.description;
    data.capacity = parseInt(data.capacity);
    data.typeIds = [data.typeIds];
    data.recurringUpdateMode = 'SINGLE';

    let response: Course;

    try {
      response = await updateBusinessCourse(businessUuid, course.businessLocation.id, course.id, data);
    } catch (e) {
      return handleValidationError(e, undefined, errorMessage);
    }

    queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, course.id, course.startAt]);
    queryClient.invalidateQueries([FETCH_TEACHER_CLASSES, businessUuid]);
    onClose();
    navigate(`${COURSES_PATH}/${response.id}/${course.originalStartAt}`);
  });

  useEffect(() => {
    const currentTeachers: Record<string, boolean> = {};

    course.teachers.forEach((teacher) => {
      currentTeachers[teacher.id] = true;
    });

    setSelected(currentTeachers);
    setTeachers(course.teachers);
  }, [course]);

  useEffect(() => {
    setActions(
      <div className="flex py-4 w-full">
        <Button variant="primary" onClick={mutate} loading={isSubmitting}>
          {t('confirm')}
        </Button>
      </div>,
    );
  }, [setActions, mutate, t, isSubmitting]);

  return (
    <div className="flex flex-col space-y-3">
      <div className="px-4">
        <GInput
          icon={<SearchIcon />}
          gsize="large"
          iconContainerClasses="bg-gray-50"
          className="sticky top-0 bg-gray-50"
          onChange={handleSearch}
        />
      </div>
      {filteredTeachers && !isLoading && (
        <div className="flex flex-col w-full h-full max-h-[calc(95vh-148px)] overflow-y-scroll">
          {filteredTeachers.map((user) => (
            <div
              key={user.id}
              className="flex justify-between items-center border-b border-gray-200 first:border-t first:pt-2.5 pb-2.5 px-4 mb-2.5 last:mb-0 last:border-b-0"
            >
              <div className="flex items-center space-x-2">
                <GAvatar key={user.id} size="2xs" path={user.profileImageUrl} />
                <div className="flex flex-col">
                  <span>{highlightQuery(user.fullName, debouncedQuery)}</span>
                  <span className="text-xs text-typo-secondary">{user.email}</span>
                </div>
              </div>
              <div>
                <GCheckbox checked={selected[user.id]} onChange={handleSelect(user)} />
              </div>
            </div>
          ))}
        </div>
      )}
      {(!users || isLoading) && (
        <div
          className={clsx(
            'flex justify-center items-center min-h-[240px] px-4 mx-4 border border-gray-200 rounded-lg',
            {
              'items-center': !users,
            },
          )}
        >
          {!users && !isLoading && (
            <div className="flex flex-col space-y-3 justify-center items-center font-neutral p-5">
              <PeopleAddIcon className="text-secondary h-8 w-8" />
              <span>{t('courseOptionsMenu.addTeacher.hint')}</span>
            </div>
          )}
          {isLoading && <GLoader variant="secondary" />}
        </div>
      )}
      {users && (
        <div className="flex flex-col px-4 justify-center text-center text-xs text-typo-secondary">
          <span>
            {t('pagination.results', {
              amount: users.content.length,
              total: users.totalElements,
            })}
          </span>
          {users.totalPages > 1 && <span>{t('pagination.refineHint')}</span>}
        </div>
      )}
    </div>
  );
};
