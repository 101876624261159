export enum HomeCardType {
  COURSE_TYPE = 'COURSE_TYPE',
  LINK = 'LINK',
}

export interface HomeCard {
  id: string;
  label: string;
  imageUrl: string;
  type: HomeCardType;
}

export interface CourseTypeHomeCard extends HomeCard {
  type: HomeCardType.COURSE_TYPE;
  courseType: { id: string };
}

export interface LinkHomeCard extends HomeCard {
  type: HomeCardType.LINK;
  link: {
    url: string;
    newWindow: boolean;
  };
}

export type HomeCardVariant = CourseTypeHomeCard | LinkHomeCard;

export interface SaveCardRequest {
  type: HomeCardType;
  label: string;
}

export interface SaveLinkCardRequest extends SaveCardRequest {
  url: string;
  newWindow: boolean;
}

export interface PatchCardRequest {
  order?: number | null;
  label?: string | null;
  link?: {
    url?: string | null;
    newWindow?: boolean | null;
  };
}
