import { useBusiness } from '@/web/hooks/use-business';
import { InboxItem } from '@/common/components/inbox-item';
import { GLoader } from '@/design-system/g-loader';
import { useQuery, useQueryClient } from 'react-query';
import { FETCH_USER_NOTIFICATIONS, getUserNotifications, readUserNotification } from '@/web/endpoints';
import { Notification } from './types';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { Outlet, useNavigate } from 'react-router-dom';
import { getMessagePath } from '@/web/routes';
import { NoResultsFound } from '@/common/components/no-results-found';
import { useTranslation } from 'react-i18next';

export const useInbox = () => {
  const { businessUuid } = useBusiness();
  const queryClient = useQueryClient();
  const { data: notifications, isFetching } = useQuery(
    [FETCH_USER_NOTIFICATIONS, businessUuid],
    async () => {
      if (businessUuid) {
        const resp = await getUserNotifications(businessUuid);

        return resp.reduce((acc: Record<string, Notification>, curr) => {
          acc[curr.notificationId] = curr;

          return acc;
        }, {});
      }

      return null;
    },
    {
      staleTime: 10000,
    },
  );

  const iterable = notifications ? Object.values(notifications) : null;
  const setRead = async (notificationId: string) => {
    if (businessUuid) {
      const result = await readUserNotification(businessUuid, notificationId);
      const notificationsCopy = notifications || {};

      const copy = {
        ...notificationsCopy,
        [notificationId]: {
          ...notificationsCopy[notificationId],
          read: true,
        },
      };

      queryClient.setQueriesData(FETCH_USER_NOTIFICATIONS, copy);

      return result;
    }
  };

  return {
    setRead,
    notifications: iterable,
    isFetching,
    size: iterable?.length,
    unread: iterable?.filter((n) => !n.read)?.length,
  };
};

export const NotificationsView = () => {
  const { notifications, isFetching } = useInbox();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : !(notifications && notifications.length) && !isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <NoResultsFound label={t('noResults.messages')} />
    </div>
  ) : (
    <div>
      {notifications &&
        notifications.map((notif) => (
          <InboxItem
            key={notif.notificationId}
            notif={notif}
            onClick={() => {
              navigate(getMessagePath(notif.notificationId));
            }}
          />
        ))}
    </div>
  );
};

export const InboxView = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper
      header={<PageHeader title={t('profileMenuView.inbox.title')} />}
      content={<Outlet />}
      contentClasses="px-0"
    />
  );
};
